import React from 'react';
import {CircularProgress, Paper} from '@mui/material';
import { useState, useEffect } from 'react';
import {getRecentActivities} from '../services/api';
import RecentActivityDashboard from '../components/RecentActivities';
import { RenderDashboardHeader, WaitForData } from '../components/EMCRenderUtils';


const RecentActivitiesPage=() =>{
  const [loading,setLoading] = useState(true);
  const [activities,setActivities] = useState([]);
  useEffect( async () => {
    getRecentActivities()
        .then (t=> {setActivities(t); setLoading(false)})
  },[]);

    return (
      <>
      <RenderDashboardHeader maintext="Recent Activities"/>
      <RecentActivityDashboard Activities={activities}/> 
      <WaitForData show={loading}/>
      </>
    );
}

export default RecentActivitiesPage;
