import React from 'react';
import {CircularProgress } from '@mui/material';
import { useState,useContext, useEffect } from 'react';
import { AuthContext } from '../App';
import {getProfile} from '../services/api';
import UserProfile from '../components/UserProfile';
import {WaitForData } from '../components/EMCRenderUtils';

const UserProfilePage = () => {
  const {state}  = useContext(AuthContext);
  const [loading,setLoading]= useState(true)
  const [prefs,setPrefs] = useState({});
  useEffect( async () => {
    getProfile(state.user)
        .then (t=> { setPrefs(t) ; setLoading(false)});
  },[]);
    return ( 
    <>
     { loading ? <></> : <UserProfile pic={state.pic} id={state.user} prefs={prefs}/> }
    <WaitForData show={loading}/>
    </>
    );
}

export default UserProfilePage;
