import React from 'react';
import {TableContainer, TableHead, List, ListItem, ListItemText, Table, TableRow,TableCell, TableBody, Container, Box, Paper, Typography } from "@mui/material/";
import { RenderDashboardHeader } from '../components/EMCRenderUtils';
import PoweredByStrava from '../img/api_logo_pwrdBy_strava_stack_light.png'

const HomePage =() => {

    const activities = [
        { name: 'Run', points: 6, units : '/km' },
        { name: 'Walk', points: 4, units : '/km' },
        { name: 'Ride (Cycling, Skating etc)', points: 2, units : '/km' },
        { name: 'Swim', points: 4, units : '/100m' },
        { name: 'Others', points: 15, units : '/hour' }
    ]
    const challenges = [
        { name: 'Winter Challenge', start: 'Week 1 (1/Jan)' , end : 'Week 6 (11/Feb)' },
        { name: 'Spring Challenge', start: 'Week 15 (08/Apr)' , end : 'Week 20 (19/May)' },
        { name: 'Summer Challenge', start: 'Week 22 (27/May)' , end : 'Week 27 (07/July)' },
        { name: 'Autumn Challenge', start: 'Week 35 (26/Aug)' , end : 'Week 40 (06/Oct)' }
    ]
    const news = [
        'Mobile friendly design',
        'Install as app on iOS, Android and PC',
        'Users can set their own weekly target points.',
        'Ordering in dashboard based on users target and acheived points.',
        'Personal login using Strava account',
        'New dashboard showing Recent activities of all members'
    ]

    const GenerateNewsList = () => {
        return (
            <List dense={true}>
                {news.map (n =>
                <ListItem>
                  <ListItemText
                    primary={n}
                  />
                </ListItem>)}
            </List> );
    }
    return (
        <Paper>
            <RenderDashboardHeader maintext="Every Move Counts" subtext="fitness group" ></RenderDashboardHeader>
            <Typography variant="h5" gutterBottom component="div">
                Welcome to the EMC Fitness App
            </Typography>
            <Typography variant="body1" gutterBottom>
                The EMC website and app have been redesigned to be mobile friendly and allow installation to both Android and iOS using a progressive web app framework.<br/>
            </Typography>
            <Typography variant="h5" gutterBottom component="div">
                <br/>New to EMC !!
            </Typography>
            <Typography variant="body1" gutterBottom>
                New users can sign up to this service by clicking on the user icon on the right hand side and choosing Register. You will then be asked to login with Strava and link your account to EMC.
                Once this is done, you can login to the app/website by using your strava login.<br/><br/>
                For more instructions on how to use the app and install the app to your phone/device Please follow the <a href='https://www.youtube.com/playlist?list=PLvaeISnroU_oDDiSLz9qq-ByuGyQm2S8H' target='_blank'>Video Guides </a><br></br>
            </Typography>
            <Typography variant="h5" gutterBottom component="div">
                <br/>Whats New?
            </Typography>
            <GenerateNewsList/>
            <Typography variant="h5" gutterBottom component="div">
                Weekly Targets and Challenges
            </Typography>
            <Typography variant="body1" gutterBottom>
                Every Member can choose their own weekly target in their profile. The range of target points is from 50 to 300. Default is 100 points <br/>
                Every Member can also choose which challenges they want to participate in. This option can be chosen in the profile. <br/><br/>

                For 2024, There will be 4 challenges - Each spanning 6 weeks.<br/>
            </Typography>
            <Table sx={{ minWidth: 200 }} size="small" aria-label="simple table">
                    <TableHead>
                    <TableRow sx={{ border: 1 ,bgcolor:"lightgray"}} >
                        <TableCell>Challenge</TableCell>
                        <TableCell align="right">Start</TableCell>
                        <TableCell align="right">End</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {challenges.map((row) => (
                        <TableRow key={row.name} sx={{ border: 1 }} >
                        <TableCell component="th" scope="row">
                            {row.name}
                        </TableCell>
                        <TableCell align="right">{row.start}</TableCell>
                        <TableCell align="right">{row.end}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>

            <Typography variant="h5" gutterBottom component="div">
                <br/>Points
            </Typography>
            <Typography variant="body1" gutterBottom>
                Each activity performed will be given points based on the below <br/><br></br>
            </Typography>
                <Table sx={{ minWidth: 200 }} size="small" aria-label="simple table">
                    <TableHead>
                    <TableRow sx={{ border: 1 ,bgcolor:"lightgray"}} >
                        <TableCell>Activity</TableCell>
                        <TableCell align="right">Points</TableCell>
                        <TableCell align="right">Units</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {activities.map((row) => (
                        <TableRow key={row.name} sx={{ border: 1 }} >
                        <TableCell component="th" scope="row">
                            {row.name}
                        </TableCell>
                        <TableCell align="right">{row.points}</TableCell>
                        <TableCell align="right">{row.units}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            
            <Typography variant="body1" gutterBottom>
              <br/>  !!!! THIS IS NOT A COMPETITION - There are no disqualification or removal from challenges if you fail to stay green.. You are still encouraged to continue in the challenge and continue your activities for the rest of the challenge.
            </Typography>
            <Typography variant="h6" gutterBottom component="div">
                Feedback
            </Typography>
            <Typography variant="body1" gutterBottom>
                Feedback can be provided here <a href='https://docs.google.com/spreadsheets/d/1Hzy_nbA3YAL9G-AX_5SE7c0Q0tUIKOFvIC3hrGczick/edit?usp=sharing' target='_blank'>link to feedback sheet </a>
            </Typography>
            <Box
                sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
                borderRadius: 1,
                }}
            >
                <img src={PoweredByStrava}/>
            </Box>
        </Paper>
    );
}
export default HomePage;
