import React from 'react';
import Typography from "@mui/material/Typography";
import SeasonalSummary from '../components/SeasonalSummary';

const Seasons = {
    spring : {
        name : 'Spring',
        startweek : 15,
        endweek  : 20 
    },
    summer : {
        name : 'Summer',
        startweek : 22,
        endweek : 27
    },
    autumn : {
        name : 'Autumn',
        startweek : 35,
        endweek : 40
    },
    winter : {
        name : 'Winter',
        startweek : 1,
        endweek : 6
    }
    
}

export const SpringDashboardPage = () => {
    return ( <div> <SeasonalSummary Season={Seasons.spring} ></SeasonalSummary></div>    );
}
export const SummerDashboardPage = () => {
    return ( <div> <SeasonalSummary Season={Seasons.summer} ></SeasonalSummary></div>    );
}
export const AutumnDashboardPage = () => {
    return ( <div> <SeasonalSummary Season={Seasons.autumn} ></SeasonalSummary></div>    );
}

export const WinterDashboardPage = () => {
    return ( <div> <SeasonalSummary Season={Seasons.winter} ></SeasonalSummary></div>    );
}
