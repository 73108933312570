import React from 'react';
import { Container, Box, Paper, Typography } from "@mui/material/";
import { RenderDashboardHeader } from '../components/EMCRenderUtils';

const Privacy =() => {
    return (
        <Paper>
            <RenderDashboardHeader maintext="Privacy policy" ></RenderDashboardHeader>
            <Typography variant="body1">
            The app and website do not collect any private information. They  display dashboards of the club members who have already linked their strava account in the club website. The linked strava information is automatically deleted after 12 months at the beginning of every calendar year.
            If at any point of time, you wish to revoke this permission, you can do this by visiting the Strava profile and revoking the consent granted. https://www.strava.com/settings/apps -&gt; Every Move Counts -&gt; Revoke Access 
            </Typography>
        </Paper>
    );
}

export default Privacy;
