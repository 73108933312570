import { Typography, Stack , Paper} from '@mui/material';
import React from 'react';
import { RenderPreferences, RenderWeeklySummary, RenderSummaryItemForDashboard} from './EMCRenderUtils.js' ;


const AnnualDashboard = ({Summary}) => {
  // console.log ('User in profile', props.user);
  //console.log ('Weekly Summary', props.user.WeeklySummary);
  // user will have the following structure { {id:id}, {name:name}, {prefs:prefs}, {WeeklySummary:[]}, {AnnualSummary:summary}}
  
  const RenderAnnualDashboard = ({SummaryList}) => {
    //console.log(SummaryList);
    return(
      <>
      {SummaryList.map(SummaryItem => SummaryItem.Summary.Total > -1 ? <Paper elevation={2} key={SummaryItem.id} > <RenderSummaryItemForDashboard item={SummaryItem}/> </Paper> : <></>)}
      </>
    );
  }

  return  (
          <>
            <RenderAnnualDashboard SummaryList={Summary}/>
          </>
  );

}

export default AnnualDashboard;