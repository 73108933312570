import { Paper} from '@mui/material';
import React from 'react';
import { RenderActivity} from './EMCRenderUtils.js' ;


const RecentActivities = ({Activities}) => {
  // console.log ('User in profile', props.user);
  //console.log ('Weekly Summary', props.user.WeeklySummary);
  // user will have the following structure { {id:id}, {name:name}, {prefs:prefs}, {WeeklySummary:[]}, {AnnualSummary:summary}}
  
  const RenderRecentActivities = ({Activities}) => {
    return(
      <>
      {Activities.map(activity =>  
            <Paper key={activity.id} elevation={1}> 
                <RenderActivity item={activity}/> 
            </Paper> 
            )}
      </>
    );
  }

  return  (
            <RenderRecentActivities Activities={Activities}/>
  );
}

export default RecentActivities;