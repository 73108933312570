import React, { useEffect, useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import { AuthContext } from '../App';
import {Card, CardHeader, Collapse,  IconButton, CardActions, CardContent, Box,Grid, Chip, Typography, Divider, Button, Snackbar, Alert, Link} from '@mui/material/';
import { signUpForEvent, signOutFromEvent, getEvent } from '../services/api';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MapIcon from '@mui/icons-material/Map';


export const EventCard = ({ item, members , type }) => {


  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));
    const Signup = async () => {
         setSignup(true); 
         signUpForEvent(type, id, loggedinUserID)
          .then (t=> getEvent(type,id).then(item => { setEvent(item); setSignup(false); }) );
   }
    const Signout = async () => {
        setSignout(true);
         signOutFromEvent(type, id, loggedinUserID)
          .then (t=> getEvent(type,id).then(item => { setEvent(item); setSignout(false); }));
   }
    const {state}  = useContext(AuthContext);
    const loggedinUserID = state.user;
    const [signup, setSignup]=  useState(false);
    const [event, setEvent] = useState(item);
    const [signout, setSignout]=  useState(false);
    const [participatingmembers, setParticipatingmembers] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const handleExpandClick = () => {
      setExpanded(!expanded);
    };
    var { id, eventTitle,  eventType, eventLink, eventStartTime, eventEndTime, eventMap, eventHeaders, eventBody, participants } = event ;
    var yesUsers = [];
    var noUsers = [] ;
    useEffect( () => {
        members.map((mem) =>
          { (participants.indexOf(mem.id.toString()) === -1) ? noUsers.push(mem) : yesUsers.push(mem) }
        ) ;
        noUsers.sort((a, b) => a.firstname.localeCompare(b.firstname));
        yesUsers.sort((a, b) => a.firstname.localeCompare(b.firstname));
        //console.log(members);
        //console.log(yesUsers);
        //console.log(noUsers);
        setParticipatingmembers(yesUsers); }, [event,members]);
    var iconString = "🏃";
    if (eventType === 'Ride') 
      iconString= "🚴";
    else if (eventType === 'Run') 
      iconString= "🏃";
    else if (eventType === 'Walk') 
      iconString= "🚶";
    
    return (
      <Box sx={{width:'98%', border:'1px solid blue'}}>
         <Card sx={{width:'100%'}}>
          <CardHeader
            avatar={
              <Avatar aria-label="activity-type"> {iconString}
              </Avatar>
            }
            action={
              <IconButton aria-label="settings">
                {(participants.indexOf(loggedinUserID) === -1 ? <Button variant="contained" color="success" onClick={()=> Signup()}> Signup </Button> : <Button variant="contained" color="warning" onClick={()=>Signout()}>Signout</Button>)}
              </IconButton>
            }
            //title={eventLink ? <Link href={eventLink} target='_blank'>{eventTitle}</Link> : eventTitle }
            title={eventTitle}
            subheader={new Date(eventStartTime).toLocaleString('en-GB',{ dateStyle: 'full', timeStyle: 'long' })}
          />
          <CardActions disableSpacing>
            <IconButton onClick={()=> eventLink !=null ? window.open(eventLink, "_blank") : window.open(eventMap, "_blank") } aria-label="open map">
              <MapIcon />
            </IconButton> 
            <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more" >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              {Object.keys(eventHeaders).map(function(keyName, keyIndex) {
                      return (
                          <Typography variant='subtitle2'> {keyName} : {eventHeaders[keyName]} </Typography>
                      )
               })}
              <Divider sx={{my:1}}/>
              {
                (eventBody.checklist != null && eventBody.checklist.length>0) ? <>
                <Box color={'grey.700'} fontSize={'0.900rem'} >
                Checklist 
                </Box>
                <Box color={'grey.600'} fontSize={'0.875rem'}><ul>
                {eventBody.checklist.map((i) => (<li> {i} </li> ))  } </ul>
                </Box> </> : <></> 
              }
              <Box color={'grey.600'} fontSize={'0.875rem'}>
                {eventBody.Description}
              </Box>
              <Box color={'grey.600'} fontSize={'0.875rem'} >
                <Divider sx={{my:2}}/>
                  <Typography>Going</Typography>
                  <Grid container spacing={1}>
                { participatingmembers.map((user) =>  
                          ( <Grid item xs={6} >
                              <Chip
                                  avatar={<Avatar src={user.pic} />}
                                  label={user.name}
                                  color="primary"
                                  variant="filled"
                                  size="small"
                                />
                            </Grid>
                            )) 
                  }
                  </Grid>
             </Box>
            </CardContent>
          </Collapse>
          </Card>
        <Snackbar open={signup} autoHideDuration={4000} >
          <Alert severity="success">
            You have successfully signed up for the event. Please wait few seconds to refresh
          </Alert>
        </Snackbar>
        <Snackbar open={signout} autoHideDuration={4000} >
          <Alert severity="warning">
            You have signed out of the event. Please wait few seconds to refresh
          </Alert>
        </Snackbar>
      </Box>
    );
  };

  
