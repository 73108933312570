import {Grid, Paper} from '@mui/material';
import React from 'react';
import { EventCard } from './EventCard.js';


const AllEvents = ({Events,Members,Type}) => {
    return(
      <Grid  justifyContent="center" alignItems="center" spacing={0.25} container>
      {Events.map(event =>  
          <Grid item xs={12} md={6}>
            <EventCard item={event} key={event.id} members={Members} type={Type}/>
          </Grid>
            )}
      </Grid>
    );
}

export default AllEvents;