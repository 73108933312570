import React from "react";
import { useState, useEffect } from 'react' ;
import { useNavigate } from 'react-router-dom' ;
import { AuthContext } from "../App";
import { CircularProgress} from "@mui/material";
import { authenticateUser } from "../services/api";

function getURLParameter(name) {
  return decodeURIComponent((new RegExp('[?|&|#]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(window.location.href) || [null, ''])[1].replace(/\+/g, '%20')) || null;
}

const Login = () => {
  const initialState = {
    user : "",
    password: "",
    active:false
  };
  const { dispatch } = React.useContext(AuthContext);
  const [data, setData] = useState(initialState);
  const navigate = useNavigate();
  useEffect( async () => {
      let code = getURLParameter("code");
      console.log("Code is ", code);
      if ( code == null || code === '')
          console.log ("Error in obtaining code");
      else{
          console.log ("Got code and authenticating with it", code);
          authenticateUser(code)
            .then (t=> t.id != null ? setData({...data, user:t.id, pic:t.pic, active: t.prefs.active}): alert(' login failed') );
      }
  },[]);
  if (data.user === "" ) {
      return (<CircularProgress/> );
  }
  else {
      dispatch({ type: "LOGIN", payload: {user:data.user, pic:data.pic, token:"elakjwerlkhwer" } }) ;
      data.active ? navigate("/recent") : navigate("/profile") ;
      return null;
  }

}

export default Login;
