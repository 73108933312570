import React from 'react';
import { Avatar, Card, CardHeader } from '@mui/material';
import {RenderUserAnnualSummary, RenderUserActivities, RenderUserWeeklySummary} from './EMCRenderUtils.js' ;


const UserDashboard = ({user,activities}) => {
  // console.log ('User in profile', props.user);
  //console.log ('Weekly Summary', props.user.WeeklySummary);
  // user will have the following structure { {id:id}, {name:name}, {prefs:prefs}, {WeeklySummary:[]}, {AnnualSummary:summary}}

  const UserCard = () => {
      return (
          <Card sx={{alignItems:'center'}}>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: 'red' }} aria-label="user" src={user.picture}>
                  U
                </Avatar>
              }
              title={ user.name}
              subheader={ user.id}
            />
          </Card>
      );
  }
  console.log(activities);
  return  (
          <>
            <UserCard/>
            <RenderUserWeeklySummary SummaryList={user.WeeklySummary} Target={user.prefs.targetpoints} />
            <RenderUserAnnualSummary Summary={user.AnnualSummary} Target={user.prefs.targetpoints * user.WeeklySummary.length} />
            <RenderUserActivities activityList={activities} />
          </>
  );

}

export default UserDashboard;