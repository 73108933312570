import React from 'react';
import { Container, Box, Paper, Typography,Icon } from "@mui/material/";
import { RenderDashboardHeader } from '../components/EMCRenderUtils';
import PoweredByStrava from '../img/api_logo_pwrdBy_strava_stack_light.png'

const AboutPage =() => {
    return (
        <Paper>
            <RenderDashboardHeader maintext="Every Move Counts" subtext="fitness group" ></RenderDashboardHeader>
            <Typography variant="h5" gutterBottom component="div">
                About EMC
            </Typography>
            <Typography variant="body1" gutterBottom>
                Every Move Counts is a group of ordinary folks who would like to improve our fitness and get motivated by others in the group.
            </Typography>
            <Typography variant="h5" gutterBottom component="div">
                Vision
            </Typography>
            <Typography variant="body1" gutterBottom>
                Our groups vision is to motivate members to maintain an active and healthy lifestyle and encourage everyone to exercise through periodic challenges.
            </Typography>
            <Typography variant="h6" gutterBottom component="div">
                Feedback
            </Typography>
            <Typography variant="body1" gutterBottom>
                Feedback can be provided here <a href='https://docs.google.com/spreadsheets/d/1Hzy_nbA3YAL9G-AX_5SE7c0Q0tUIKOFvIC3hrGczick/edit?usp=sharing' target='_blank'>link to feedback sheet </a>
            </Typography>
            <Box
                sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
                borderRadius: 1,
                }}
            >
                <img src={PoweredByStrava}/>
            </Box>
        </Paper>
    );
}
export default AboutPage;
