import React from 'react';
import Typography from "@mui/material/Typography";
import Members from "../components/Members";

function MembersPage() {
    return (
        <div className="MuiGrid-container">
        <Members/>
        </div>
    );
}

export default MembersPage;
