import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { AuthContext } from '../App';
import Typography from "@mui/material/Typography";
import { getUserSummary, getUserActivities } from '../services/api';
import UserDashboard from '../components/UserDashboard';
import {WaitForData} from '../components/EMCRenderUtils.js';


const UserDashboardPage = ({id}) => {
        const {state}  = useContext(AuthContext);
        const [user,setUser] = useState({});
        const [activities,setActivities] = useState([]);
        const [res1, setRes1] = useState(false);
        const [res2, setRes2] = useState(false);
        useEffect( async () => {
          getUserSummary(state.user)
              .then (t=> { t.length == 1? setUser(t[0]): setUser({}) ; setRes1(true); });
        },[]);
        useEffect( async () => {
          getUserActivities(state.user)
              .then (t=> t.Items)
              .then (t=> { t.length == 1? setActivities(t[0].activities) : setActivities([]) ; setRes2(true); });
        },[]);
          return ( (res1 && res2) ?  <UserDashboard user={user} activities={activities}/> : <WaitForData show={!(res1 && res2)}/>);
}


export default UserDashboardPage;
