import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import PublicAppBar from '../components/PublicAppBar';

const PublicLayout = ({children}) => {
    return (
      <Container sx={{p:0.2}} maxWidth="md">
        <Box sx={{ p:0.25, border: '0px dashed grey',  bgcolor: '#606060'}}>
             <PublicAppBar/> 
             {children}
        </Box>
      </Container>
  );
}

export default PublicLayout;

