import {augmentLeanUsers} from '../components/EMCUtils';

//const baseurl='https://09zopybgw3.execute-api.eu-west-1.amazonaws.com/test';
//const baseurl='https://beta.fitness.everymovecounts.dk/prod';
//const baseurl='https://beta.fitness.everymovecounts.dk/api';
const baseurl='https://prodapi.fitness.everymovecounts.dk/api';
//const baseurl='https://api.everymovecounts.dk';
const fltr='active';
export const getWeeklySummary = (weeknum) => {
    return fetch(baseurl+'/summary/weekly/'+fltr+ '/'+weeknum) 
      .then(data => data.json())
      .then(resp => resp.Items)
}

export const savePreferences = (id, prefs) => {
  return fetch(baseurl + '/athlete/' + id + '/preferences', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(prefs),
      })
    .then(response => response.json())

}

export const getSeasonalSummary= (name,startweek, endweek) => {
    const seasonname=name.toLowerCase();
    return fetch(baseurl+'/summary/seasonal/'+seasonname+'/'+startweek + "/" +endweek) 
      .then(data => data.json())
      .then(resp => resp.Items)

}

export const getProfile = (id) => {
    return fetch(baseurl+'/athlete/'+id+ '/preferences') 
      .then(data => data.json())
}
export const getUserSummary = (id) => {
    return fetch(baseurl+'/summary/'+id) 
      .then(data => data.json())
      .then(resp => resp.Items)
}
export const getAnnualSummary = () => {
    return fetch(baseurl+'/summary/yearly/'+fltr) 
      .then(data => data.json())
      .then(resp => resp.Items)
}

export const getUsersLean = async () => {
    return fetch(baseurl+ '/athletes')
    	.then(response => response.json())
        .then(data => augmentLeanUsers(data.Items));
    };  


export const getUserActivities = (id) => {
    return fetch(baseurl+'/athlete/'+id+'/activities') 
      .then(data => data.json()) ;
}

export const getRecentActivities = () => {
  return fetch (baseurl+ '/activities/recent')
    .then(data=>data.json())
    .then(data=> data.Items);
}

export const authenticateUser = (code) => {
    return fetch(baseurl+'/loginwithstrava',  
      {
        method: 'POST', 
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({AuthorizationCode:code}) 
      })
      .then(data => data.json())
}

export const registerUser = (code)  => {
    return fetch(baseurl+'/registerwithstrava' ,
      {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({AuthorizationCode:code}) // body d
      }) 
      .then(data => data.json())
}

export const getEvents = (type) => {
  return fetch (baseurl+ '/events/'+type)
    .then(data=>data.json())
    .then(data=> data.Items);
}

export const getEvent = (type,id) => {
  return fetch (baseurl+ '/events/'+ type+'/'+id)
    .then(data=>data.json())
    .then(data=> data.Item);
}
export const signUpForEvent = (type,eventid,userid) => {
  return fetch (baseurl+ '/events/'+ type +'/'+ eventid+ '/'+ userid+ '/signup')
    .then(data=>data.json())
}

export const signOutFromEvent = (type,eventid,userid) => {
  return fetch (baseurl+ '/events/'+ type +'/'+ eventid+ '/'+ userid+ '/signout')
    .then(data=>data.json())
}
