import React from 'react';
import { Avatar, Card, CardHeader, Typography } from '@mui/material';
import { RenderPreferences} from './EMCRenderUtils.js' ;


const UserProfile = ({id,pic,prefs}) => {
  // user will have the following structure { {id:id}, {name:name}, {prefs:prefs}, {WeeklySummary:[]}, {AnnualSummary:summary}}

  const UserCard = () => {
      return (
          <Card sx={{alignItems:'center'}}>
            <CardHeader
              avatar={
                <Avatar  sx={{ bgcolor: 'red' , width: 56, height: 56 }} aria-label="user" src={pic}/>
              }
              title={<Typography variant="h6">View and change your preferences</Typography>}
            />
          </Card>
      );
  }

  return  (
          <>
            <UserCard/>
            <RenderPreferences id={id} prefs={prefs}/>
          </>
  );

}

export default UserProfile;