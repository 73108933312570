import { Typography, Stack , Paper} from '@mui/material';
import React from 'react';
import { CompareRatioForDashBoard, AugmentItemsForDashboard} from './EMCUtils.js';
import { useState, useEffect } from 'react';
import { getSeasonalSummary } from '../services/api.js';
import { RenderSeasonalSummaryRow , RenderDashboardHeader , WaitForData} from './EMCRenderUtils.js';

const SeasonalSummary = ({Season}) => {
  
  const [loading,setLoading] = useState(true); 
  const [summary,setSummary] = useState([]);
  useEffect( async () => {
    getSeasonalSummary(Season.name, Season.startweek, Season.endweek)
        .then(t=> AugmentItemsForDashboard(t) )
        .then(t=>t.sort(CompareRatioForDashBoard))
        .then (t=> {setSummary(t); setLoading(false)});
  },[]); 
  
  const RenderSeasonalDashboard = () => {
    return(
      <Stack spacing={0.2}>
        {summary.map(SummaryItem => <RenderSeasonalSummaryRow item={SummaryItem}></RenderSeasonalSummaryRow>)}
      </Stack>
    );
  }

  return  (
          <>
            <RenderDashboardHeader maintext={`${Season.name} Dashboard`} subtext={`Week  ${Season.startweek} to ${Season.endweek}` }/>
            <RenderSeasonalDashboard/>
            <WaitForData show={loading}/>
          </>
  );

}

export default SeasonalSummary;
