import React from 'react';
import {useState, useEffect} from 'react' ;
import {Container, Divider, Paper } from '@mui/material';
import {WaitForData, RenderMember, RenderDashboardHeader } from './EMCRenderUtils';
import { getUsersLean } from '../services/api';

const Members = () => {
    const [ready,setReady]  = useState(false);
    const [data,setData] = useState([]);

    useEffect(() => {
      getUsersLean()
      .then (res=> { setData(res) ; setReady(true)});
    },[]);

 
    return (<Paper sx={{p:0.1}}> <RenderDashboardHeader maintext="EMC Members"/> { data.map( d => <RenderMember item={d} />) } <WaitForData show={!ready} /></Paper>);
}

const Atl = (athlete) => {

  return <RenderMember item={athlete}/>;
}
export default Members;
