import React from 'react';
import {CircularProgress  } from '@mui/material';
import { useState, useEffect } from 'react';
import { getEvents, getUsersLean } from '../services/api';
import AllEvents from '../components/AllEvents';
import { RenderDashboardHeader, WaitForData } from '../components/EMCRenderUtils';

const EMCEventsPage=() =>{
  const [loading,setLoading] = useState(true);
  const [events,setEvents] = useState([]);
  const [members,setMembers] = useState([]);
  useEffect( async () => {
    getEvents('emc')
        .then (t=> { setEvents(t); setLoading(false)});
  },[]);
  useEffect( async () => {
    getUsersLean()
        .then (t=> setMembers(t));
  },[]);

  
    return (
      <>
      <RenderDashboardHeader maintext="EMC Events" subtext="2024"/>
      <AllEvents Events={events} Members={members} Type="emc"/> 
      <WaitForData show={loading}/>
      </>
    );
}

export default EMCEventsPage;
