import React from 'react';
import {useEffect,useState} from 'react';
import { useNavigate } from 'react-router-dom' ;
import { registerUser } from '../services/api';
import { WaitForData } from '../components/EMCRenderUtils';
import { Snackbar, Alert } from '@mui/material';

//let urlElements = window.location.href.split('/register');
//var homeurl =urlElements[0]+'/home';a
var homeurl = 'https://www.everymovecounts.dk/' ;
    
function getURLParameter(name) {
  return decodeURIComponent((new RegExp('[?|&|#]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(window.location.href) || [null, ''])[1].replace(/\+/g, '%20')) || null;
}

function Register()
{
    const [loading,setLoading] = useState(true);
    const [success,setSuccess] = useState(false);
    const [failure,setFailure] = useState(false);
    const navigate = useNavigate();
        useEffect( async () => {
            let code = getURLParameter("code");
            console.log("Code is ", code);
            if ( code == null || code === '')
                console.log ("Error in obtaining code");
            else{
                console.log ("Got code and registering it", code);
            registerUser(code)
              .then (t=> { console.log(t); setLoading(false) ; t.message === 'Registered Successfully'? setSuccess(true) : setFailure(true) });
            }
        },[]);

         const navigateToLogin = () => {
                navigate("/login")
        };
         const navigateToHome = () => {
                navigate("/")
        };

        return (
        <div>
            <WaitForData show={loading}/>
            <Snackbar open={success} autoHideDuration={4000} onClose={navigateToLogin}>
                <Alert onClose={navigateToLogin} severity="success" sx={{ width: '100%' }}>
                    Registration successful.. Taking to Login page!
                 </Alert>
            </Snackbar>
            <Snackbar open={failure} autoHideDuration={4000} onClose={navigateToHome}>
                <Alert onClose={navigateToHome} severity="error" sx={{ width: '100%' }}>
                    Registration Failed.. Taking to Home Page!
                 </Alert>
            </Snackbar>
        </div>
    );
}



export default Register;
