import React from 'react';
import {Typography, Card, CardContent, CardActions, Divider, Box,IconButton,Paper} from "@mui/material";
import ConnectWithStrava from "../img/btn_strava_connectwith_orange.png";
import { RenderDashboardHeader } from '../components/EMCRenderUtils';

//let urlElements = window.location.href.split('/settings');
//var registerurl =urlElements[0]+'register';
//console.log("Registration URL is",registerurl);
var registerurl = 'https://www.everymovecounts.dk/register'
var redirurl = 'https://www.strava.com/oauth/authorize?client_id=52553&redirect_uri=' + registerurl + '&response_type=code&approval_type=auto&scope=read,activity:read' ;

const SignupPage= () => {
    return (
        <Paper >
            <RenderDashboardHeader maintext= "Welcome new EMC user" ></RenderDashboardHeader>
            <Card>
            <CardContent>
                <Typography variant="h5" component="div">
                    Connect Strava account to EMC
                </Typography>
                <Typography variant="body2">
                    <br/>
                    At EMC we use fitness data from Strava to provide relevant dashboards and challenges for our users.
                    <br/>
                    <br/>
            Start by connecting your Strava account to EMC.
                    <br/> When you click the below button, you will be asked to sign into Strava and allow Every Move Counts access to your data.
                    well meaning and kindly.
                    <br />
                    <br />
                    <br />
                </Typography>
            </CardContent>
                <CardActions>
                    <IconButton variant="contained" color="primary" href={redirurl}>
                    <img src={ConnectWithStrava} alt="Login with Strava" />
                    </IconButton>
                </CardActions>
            </Card>
          <Box sx={{display:'flex', height:200, border:'0px dotted grey', alignItems:'center', justifyContent:'center'}}>
          </Box>
        </Paper>
    );
}

export default SignupPage;
