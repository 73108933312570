import React from "react";
import { IconButton, Box, Paper, Stack, Typography } from "@mui/material";
import { RenderDashboardHeader } from '../components/EMCRenderUtils';
import ConnectWithStrava from "../img/btn_strava_connectwith_orange.png";


//var registerurl = 'http://beta.everymovecounts.dk/loginwithstrava'
var registerurl = 'http://www.everymovecounts.dk/loginwithstrava'
var redirurl = 'https://www.strava.com/oauth/authorize?client_id=52553&redirect_uri=' + registerurl + '&response_type=code&approval_type=auto&scope=read,activity:read' ;

const LoginPage = () => {
      return (
        <Paper >
          <RenderDashboardHeader maintext= "Login with strava" ></RenderDashboardHeader>
          <Box sx={{display:'flex', height:300, border:'0px dotted grey', alignItems:'center', justifyContent:'center'}}>
            <IconButton variant="contained" color="primary" href={redirurl}>
              <img src={ConnectWithStrava} alt="Login with Strava" />
            </IconButton>
          </Box>
        </Paper>
    );

}

export default LoginPage;
